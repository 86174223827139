import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import GatsbyImage from 'gatsby-image'
import Background from '../components/Background'
import Social from '../components/Social'

import { device } from '../styles/config'

const Container = styled.div`
  ${ props => props.theme.wrapper }
  ${ props => props.theme.paddingTop }
  ${ props => props.theme.backgroundTop }
`

const Text = styled(Col)`
  columns: 3;
  column-gap: 1.875rem;
  margin-bottom: 6.875rem;

  @media ${ device.md } {
    columns: 2;
    column-gap: 1.875rem;
  }

  @media ${ device.sm } {
    columns: initial;
    column-gap: initial;
  }

  p {
    margin-bottom: 1rem;
  }
`

const ImageContainer = styled(Col)`
  margin-bottom: 1.875rem;

  &:only-child {
    margin-bottom: 0;
  }
`

const AboutPage = ({ data: { datoCmsAbout: { header, text, images } } }) => (
  <Background openTop>
    <Container>
      <Row>
        <Col xs={12}>
          <h1>{header}</h1>
        </Col>
      </Row>
      <Row>
        <Text xs={12} dangerouslySetInnerHTML={{ __html: text }}/>
      </Row>
      <Row>
        {images.map((image, index) => (
          <ImageContainer key={index} sm={6} xs={12}>
            <GatsbyImage fluid={image.fluid}/>
          </ImageContainer>
        ))}
      </Row>
      <Social/>
    </Container>
  </Background>
)

export const query = graphql`
  query AboutPage {
    datoCmsAbout {
      header
      text
      images {
        fluid(maxWidth: 300, imgixParams: {
          fm: "jpg",
          auto: "compress,format"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
  }
`

export default AboutPage
